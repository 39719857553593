import * as React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import GlobalStyles from '../styles/globalStyles';
import { Helmet } from 'react-helmet';
import logo from '../images/pro2go_logo.png';
import { graphql } from 'gatsby';

const Header = styled.h1`
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #00483b;
  font-size: ${(props) => props.$fontSize ?? '45px'};
  line-height: ${(props) => props.$lineHeight ?? ' 50px'};
  letter-spacing: 0.02em;
  margin-bottom: 35px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: ${(props) => props.$fontSize ?? '35px'};
    line-height: ${(props) => props.$lineHeight ?? ' 40px'};
  }
`;

const TopBar = styled.div`
  background: #083a81;
  padding-bottom: 20px;
`;

const TopTagLine = styled.p`
  padding: 11px 0 20px 18px;
  text-transform: uppercase;
  font-family: 'Gotham-Bold', arial, sans-serif;
  color: #0397d6;
  float: none;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 13px;
  line-height: 13px;
  @media (max-width: 450px) {
    font-size: 9px;
    line-height: 9px;
  }
`;

const LogoStyle = styled.img`
  margin: auto;
  display: flex;
  padding: 20px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const LinkStyle = styled.a`
  text-decoration: none !important;
  color: #083a81;
  text-align: left;
  :hover {
    text-decoration: none;
    color: #1fb25a;
    outline: none;
  }
`;

const NotFoundPage = ({ data, location }) => {
  const [loaded, setLoaded] = React.useState(false);
  const serializeRoutes = (route) => {
    if (route?.slice(-1) !== '/' && !route.includes('.html')) {
      route = route + '/';
    }
    if (route?.slice(-1) === '/' && !route.includes('.html')) {
      route = route + 'index.html';
    }
    return route;
  };
  const allSlug = data?.allSitePage?.nodes?.map(({ path }) =>
    serializeRoutes(path)
  );

  React.useEffect(() => {
    let route = location?.href;
    if (location?.href !== location?.href?.toLowerCase()) {
      route = location?.href?.toLowerCase();
    }
    route = serializeRoutes(route);

    if (
      (!route?.includes('.html') || !route?.includes('404')) &&
      allSlug?.includes(route)
    ) {
      window.location.href = route;
    } else {
      window.location.href = '/index.html';
    }
    setLoaded(true);
  }, []);
  return loaded ? (
    <>
      <GlobalStyles />
      <Helmet>
        <title>Pro2Go :: Page Not Found</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="format-detection" content="telephone=no"></meta>
        <meta
          name="description"
          content="In less than a minute you can complete your transaction and have a full propane cylinder in hand without ever having to wait for an associate or step foot inside the store."
        />
        <meta name="generator" content="WordPress 5.3.2"></meta>
      </Helmet>
      <TopBar>
        <LogoStyle src={logo} title={'pro2go'} />
      </TopBar>
      <Container className="pt-3" style={{ textAlign: 'center' }}>
        <Header>Page Not Found</Header>
        <LinkStyle href={`/`}>BACK TO HOME</LinkStyle>
      </Container>
    </>
  ) : (
    <></>
  );
};

export default NotFoundPage;
export const pageQuery = graphql`
  query AllPagesQuery {
    allSitePage {
      nodes {
        path
      }
    }
  }
`;
